import React, { useState } from "react"
import { Button, Card, CardBody, CardText, Col, Tooltip } from "reactstrap"
import { GoogleIcon } from "../../Canais/components/GoogleIcon"
import SalesforceIcon from "./SalesforceIcon"

interface IntegrationCardProps {
	onDelete: (integrationId: string | number) => void
	integration: { id: number; nome_sistema: string; color?: string; icon?: string; hash_sistema: string }
	identificador: string
}

export function IntegrationCard({ integration, identificador, onDelete }: IntegrationCardProps) {
	return (
		<Col xs={12} sm={6} md={4} lg={3} key={integration.id}>
			<Card
				style={{
					background: integration?.color || "#eee",
					height: "400px",
				}}
			>
				<CardBody className="d-flex align-items-center justify-content-center flex-column">
					<CardText
						className="font-weight-bold"
						style={{
							fontSize: "2rem",
						}}
					>
						<span>
							{integration?.hash_sistema === "salesforce-lead-collect" ? (
								<SalesforceIcon size={48} />
							) : (
								<i className={integration?.icon || "fas fa-portrait"} />
							)}
						</span>
					</CardText>
					<CardText className="font-weight-bold text-center">{integration?.nome_sistema}</CardText>
					<CardText>
						<span>{identificador}</span>
					</CardText>
					<Button
						style={{ position: "absolute", top: "8px", right: "8px" }}
						close
						onClick={() => onDelete(integration?.id)}
					/>
				</CardBody>
			</Card>
		</Col>
	)
}
