import React, { useEffect, useContext, useState, useRef } from "react";
import {
  CardBody,
  Button,
  Card,
  Row,
  Col,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./style.css";
import api from "../../../../../../services/api";
import EmpresaContext from "../../../../../../contexts/Empresa";
import NotificationAlert from "react-notification-alert";

export default ({
  displayForm,
  setDisplayForm,
  setDisplayMain,
  automacoesInfo,
  eventoID,
  loadForm,
  reqAutomacoes,
  acoesBtnInfo,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [acoes, setAcoes] = useState([]);
  const [state, setState] = useState(false);
  const [nomeAcao, setNomeAcao] = useState("");
  const [descricaoAcao, setdescricaoAcao] = useState("");
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [acoesID, setAcoesID] = useState(0);
  const [situacao, setSituacao] = useState(new String());
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [acoesBtn, setAcoesBtn] = useState([]);
  const notificationAlert = useRef<any>();

  const notify = (type: any, msg: string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text" style={{ zIndex: 100 }}>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    if (notificationAlert.current)
      notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    setAcoes(automacoesInfo);
    setAcoesBtn(acoesBtnInfo);
  }, [automacoesInfo, acoesBtnInfo]);

  interface acoes {
    descricaoAcao: string;
    id: number;
    nomeAcao: string;
    situacao: String;
  }

  interface acoesBtn {
    id: number;
    nome: string;
    descricao: string;
  }

  function stateSituacao() {
    setState(!state);
    situacao === "A" ? setSituacao("I") : setSituacao("A");
  }

  async function alterStateSituacao() {
    await api
      .patch(
        `automacoes/${empresaSelecionada?.id}/acoes/situacao/${acoesID}/${eventoID}`,
        {
          situacao: situacao,
        }
      )
      .then((result) => {
        if (result.status === 200) {
          situacao === "A"
            ? notify("success", "Automação ativada")
            : notify("success", "Automação desativada");
          loadForm();
          reqAutomacoes();
          setTimeout(() => {
            setShowModal(!showModal);
          }, 1000);
        }
      })
      .catch((err) => {
        notify("danger", "Erro ao alterar o estado da automação");
      });
  }

  async function insertAcao(acaoID: number) {
    await api
      .post(`/automacoes/${empresaSelecionada?.id}/acoes/novaacao`, {
        acaoID: acaoID,
        eventoID: eventoID,
      })
      .then((result) => {
        notify("success", "Nova automação ativada");
        reqAutomacoes();
        setDropdownOpen(!dropdownOpen);
        loadForm();
      })
      .catch((err) => {
        notify("danger", "Erro ao ativar nova automação");
      });
  }

  function toggleDropDown() {
    setDropdownOpen(!dropdownOpen);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: "700px" }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Configurar automação
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setShowModal(!showModal);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body m-6">
          <Row className="justify-content-between">
            <Col>
              <p className="m-0 font-weight-bold">{nomeAcao}</p>
              <p className="m-0">{descricaoAcao}</p>
            </Col>
            <div>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  checked={state}
                  onClick={stateSituacao}
                />
                <span className="custom-toggle-slider rounded-circle"></span>
              </label>
            </div>
          </Row>
        </div>
        <div className="modal-footer">
          <Row>
            <Col>
              <div className="float-right ">
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                >
                  Fechar
                </Button>
                <Button color="primary" onClick={alterStateSituacao}>
                  Salvar
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <Card style={{ display: displayForm }}>
        <CardBody>
          <div>
            <h1>Minhas automações</h1>
          </div>
          <CardBody>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
              <DropdownToggle
                caret
                direction="down"
                color={"primary"}
                style={{ marginRight: 5 }}
              >
                Nova Automação
              </DropdownToggle>
              <DropdownMenu>
                {acoesBtn.map((values: acoesBtn) => {
                  return (
                    <DropdownItem
                      onClick={() => {
                        insertAcao(values.id);
                      }}
                    >
                      {values.descricao}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </CardBody>
          {acoes.map((values: acoes) => {
            return (
              <div className="m-3 box-automacao">
                <div className="p-2">
                  <Row
                    className={`g-col-6 m-2 w-100 justify-content-between checklist-item ${
                      values.situacao === "A"
                        ? "checklist-item-success"
                        : "checklist-item-secondary"
                    }`}
                  >
                    <Col>
                      <p className="m-0 font-weight-bold">{values.nomeAcao}</p>
                      <p className="m-0">{values.descricaoAcao}</p>
                    </Col>
                    <div>
                      <Button
                        onClick={() => {
                          setNomeAcao(values.nomeAcao);
                          setdescricaoAcao(values.descricaoAcao);
                          setState(values.situacao === "A" ? true : false);
                          setShowModal(!showModal);
                          setAcoesID(values.id);
                          setSituacao(values.situacao);
                        }}
                        style={{
                          backgroundColor: "#0947D8",
                          color: "white",
                          border: "none",
                        }}
                      >
                        Configurar
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            );
          })}
          <Row className="p-2 g-col-6 m-2 justify-content-end">
            <Button
              onClick={() => {
                setDisplayForm("none");
                setDisplayMain("flex");
              }}
              style={{ backgroundColor: "#c2c8cd", border: "none" }}
            >
              Voltar
            </Button>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
