import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import {
	CardText,
	Button,
	Card,
	CardBody,
	Col,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Row,
	Badge,
	Alert,
	Spinner,
	FormGroup,
	Input,
	Label,
	Container,
} from "reactstrap"

import api from "../../../../services/api"
import ReactBSAlert from "react-bootstrap-sweetalert"
import { useHistory, useLocation, useParams } from "react-router-dom"
import AuthContext from "../../../../contexts/Auth"
import Axios from "axios"
import EmpresaContext from "../../../../contexts/Empresa"

interface Sistema {
	id: number
	nome: string
	tipo: string
	hash: string
	app_id: string
	scope: "E" | "U" | "A"
}

export default function ExternalAppCallback() {
	const [sistema, setSistema] = useState<Sistema>()

	const { app }: { app: string } = useParams()
	const { user } = useContext(AuthContext)
	const { empresaSelecionada } = useContext(EmpresaContext)

	const history = useHistory()

	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)

	const integrationCode = queryParams.get("code")

	const getSistema = async () => {
		const response = await api.get(`esb/integracoes/sistemas?hash=${app}`)

		setSistema(response.data[0])
	}

	const saveIntegration = async () => {
		try {
			//TODO: Vão existir casos que n precisamos enviar a empresa
			const response = await api
				.post(`esb/integracoes/auth/${app}/`, {
					code: integrationCode,
					userId: user?.id,
					empresaId: empresaSelecionada?.id,
				})
				.then((res) => res.data)

			if (sistema?.scope === "E") {
				if (response.status === 200 || response.status === 201) {
					history.push(`/admin/integracoes/?integrationStatus=success&message=${response.message}`)
				} else if (response.message) {
					history.push(`/admin/integracoes/?integrationStatus=fail&message=${response.message}`)
				} else {
					history.push(`/admin/integracoes/?integrationStatus=fail&message=Falha ao realizar integração`)
				}
			} else {
				if (response.status === 200 || response.status === 201) {
					history.push(`/admin/me/?integrationStatus=success&message=${response.message}`)
				} else if (response.message) {
					history.push(`/admin/me/?integrationStatus=fail&message=${response.message}`)
				} else {
					history.push(`/admin/me/?integrationStatus=fail&message=Falha ao realizar integração`)
				}
			}
		} catch (error) {
			console.log(error)

			if (sistema?.scope === "E") {
				history.push(`/admin/integracoes/?integrationStatus=fail&message=Falha ao realizar integração`)
			} else {
				history.push(`/admin/me/?integrationStatus=fail&message=Falha ao realizar integração`)
			}
		}
	}

	useEffect(() => {
		getSistema()

		if (integrationCode) saveIntegration()
	}, [])

	return (
		<>
			<Row style={{ margin: "2rem 1.5rem" }}>
				<Col>
					<h1 className="d-flex" style={{ gap: ".65rem" }}>
						Aguarde, estamos integrando o <h1 style={{ color: "#0747D6" }}>{sistema?.nome}</h1> para você!
					</h1>
				</Col>
			</Row>
		</>
	)
}
