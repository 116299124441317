import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import { usePersistedState } from '../../../../../hooks'
import moment from 'moment'

import {
    // global options for the charts
    BarParcial,
} from "../../../../../variables/charts";
import { Bar } from "react-chartjs-2";
import { SpinnerContainer } from '../styles'
import Select2 from "react-select2-wrapper";
import {
    Spinner,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    InputGroup,
    FormGroup,
    PopoverBody,
    UncontrolledPopover,
} from "reactstrap";

function VendasMetaUltimosMeses({ notify, mes, ano, equipe, colaborador, tipoProduto, unidade, cardHeight, periodo }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [loading, setLoading] = useState(false)
    const [emptyValue, setEmptyValue] = useState(null)

    const [dados, setDados] = useState({})

    useEffect(() => {
        loadGrafico()
    }, [mes, ano, equipe, colaborador, tipoProduto, unidade, periodo, empresaSelecionada])


    useEffect(() => {
        createChartData()
    }, [itens])

    async function loadGrafico() {
        setLoading(true)
        try {
            const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/vendas-meta-ultimos-meses`, {
                params: {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    tipoProduto,
                    unidade,
                    periodo: periodo ? {
                        data_inicio: periodo.data_inicio,
                        data_fim: periodo.data_fim
                    } : null
                }
            })
            setItens(await response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar gráfico de Vendas X Meta dos Últimos Meses')
        }
        setLoading(false)
    }

    function createChartData() {
        const datasets = []
        const periodos = itens.map(periodos => periodos);
        const labels = periodos.map(periodo => moment(periodo.periodo).format('MMM/YYYY'))

        datasets.push({
            label: 'Vendas',
            backgroundColor: '#008000',
            borderColor: '#008000',
            data: itens.map(item => parseFloat(item.vendas))
        })

        datasets.push({
            label: 'Meta',
            backgroundColor: '#0747d6',
            borderColor: '#0747d6',
            data: itens.map(item => parseFloat(item.meta))
        })

        setDados({
            labels,
            datasets
        })
    }

    const options = {
        tooltips: {
            // aqui eu consigo realizar a tranformação do dado para converter
            callbacks: {
                label: (context) => {
                    if (unidade && unidade == 'M') {
                        let value = context.value;
                        return `R$ ${Number(value).toLocaleString('pt-br', {style: "currency", currency: "BRL"})}`;
                    } else {
                        return context.value;
                    }
                },
            },
            // 
            mode: "index",
            intersect: false
        },
        responsive: true,
        scales: {
            xAxes: [{
                barThickness: 20
            }]
        }
    }

    return (
        <Card style={{ height: cardHeight }}>
            {/* utilizando a class 'bg-gradient-default' é possivel alterar a cor de fundo */}
            <CardHeader className='bg-gradient-default'>
                <h5 class="h3 mb-0" style={{color: "white"}}>Últimos 06 Meses</h5>
                <h6 class="surtitle" style={{color: "white"}}>Vendas X Meta dos últimos 06 meses</h6>
            </CardHeader>
            <CardBody className='bg-gradient-default'>

                <div className="">
                    {
                        loading ?
                            <SpinnerContainer>
                                <Spinner
                                    color='primary'
                                    size='lg'
                                    style={{
                                        alignSelf: 'center'
                                    }}
                                />
                            </SpinnerContainer>
                            :
                            <div className='chart' style={{ height: cardHeight - 125 }}>
                                <Bar
                                    data={{ ...dados }}
                                    options={options}
                                    className="chart-canvas"
                                />
                            </div>
                    }
                </div>


            </CardBody>
        </Card>
    )
}

export default VendasMetaUltimosMeses;