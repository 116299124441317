import React, { useState } from "react";
import Select from "react-select";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
} from "reactstrap";
import { GoogleIcon } from "../components/GoogleIcon";

import PhoneInput from "../../../../../../../components/selects/Numero/PhoneInput";

interface NovoCanalModalProps {
  isOpen: boolean;
  onSave: any;
  canais: any;
  onClose: () => void;
  isLoading: boolean;
  googleLogin: () => void;
}

export function NovoCanalModal({
  isOpen,
  onSave,
  canais,
  onClose,
  isLoading,
  googleLogin,
}: NovoCanalModalProps) {
  const [identificador, setIdentificador] = useState("");
  const [canalSelecionado, setCanalSelecionado] = useState<string | undefined>(
    undefined
  );

  function handleSaveCanal() {
    // Apenas salva o valor sem validação
    onSave({ canalSelecionado, identificador });
    handleClose();
  }

  function handleClose() {
    setIdentificador("");
    setCanalSelecionado(undefined);
    onClose();
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: "calc(2.75rem + 2px)",
    }),
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      size="xl"
      onClosed={() => setCanalSelecionado(undefined)}
    >
      <ModalHeader toggle={handleClose}>Novo Canal</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="canal">Canal de Contato</Label>
            <Select
              id="canal"
              className="basic-single"
              classNamePrefix="select"
              isDisabled={isLoading}
              isSearchable
              styles={customStyles}
              placeholder="Selecione um Canal"
              options={canais?.map((canal: any) => ({
                value: canal.id,
                label: canal.nome,
              }))}
              onChange={(e: any) => {
                setCanalSelecionado(e.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            {canalSelecionado == "25" ? (
              <>
                <Label for="contato-id">Seu número do WhatsApp</Label>
                <PhoneInput
                  value={identificador}
                  onChange={(event) => setIdentificador(event.target.value)}
                />
              </>
            ) : (
              <>
                <Label for="contato-id">
                  {canalSelecionado == "12"
                    ? "Seu email"
                    : "Link do seu perfil"}
                </Label>
                <InputGroup>
                  <Input
                    disabled={!canalSelecionado || isLoading}
                    id="contato-id"
                    type={canalSelecionado == "12" ? "email" : "url"}
                    value={identificador}
                    onChange={(e) => setIdentificador(e.target.value)}
                  />
                </InputGroup>
                {canalSelecionado == "12" && (
                  <div className="d-flex flex-column">
                    <div
                      className="my-4 bg-primary"
                      style={{ height: "1px", width: "100%", opacity: 0.75 }}
                    ></div>
                    <Button
                      className="mb-2 d-flex align-items-center justify-content-center"
                      onClick={() => googleLogin()}
                      style={{
                        backgroundColor: "#4285f4",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        padding: "10px",
                        cursor: "pointer",
                        gap: "8px",
                        height: "46px",
                      }}
                    >
                      <div
                        className="bg-white p-1"
                        style={{ borderRadius: "4px" }}
                      >
                        <GoogleIcon />
                      </div>
                      <span className="text-white">
                        Fazer login com o Google
                      </span>
                    </Button>
                    <small className="text-muted">
                      Ao se conectar utilizando seu e-mail do Google, você terá
                      a conveniência de sincronizar automaticamente os eventos
                      do Playnee com o seu Calendário Google.
                    </small>
                  </div>
                )}
              </>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSaveCanal} disabled={isLoading}>
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
