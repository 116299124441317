import Ws from "@adonisjs/websocket-client"

class WebSocketClient {
	constructor() {
		this.ws = null
		this.subscriptions = {}
	}

	connect(url) {
		this.ws = Ws(url, {
			path: process.env.REACT_APP_API_WSS_URL_PATH,
			reconnection: false,
		})
		const token = JSON.parse(localStorage.getItem(`@playnee-token`)).token

		this.ws.withApiToken(token).connect()

		this.ws.on("open", () => {
			console.log("Conexão estabelecida com o servidor WebSocket.")
		})

		this.ws.on("close", () => {
			console.log("Conexão WebSocket fechada.")
		})

		this.ws.on("error", (error) => {
			console.error("Erro na conexão WebSocket:", { error })
		})

		this.ws.on("reconnecting", () => {
			console.log("Tentando reconectar...")
		})

		this.ws.on("reconnect", () => {
			console.log("Conexão WebSocket reconectada com sucesso.")
		})

		this.ws.on("reconnect_error", (error) => {
			console.error("Erro na tentativa de reconexão:", error)
		})
	}

	subscribe(topic, callback) {
		if (!this.ws) {
			console.error("A conexão WebSocket não foi estabelecida.")
			return
		}

		if (this.subscriptions[topic]) {
			console.warn(`Já inscrito no tópico: ${topic}`)
			return
		}

		const channel = this.ws.subscribe(topic)
		this.subscriptions[topic] = channel

		channel.on("message", (data) => {
			if (callback) {
				callback(data)
			}
		})

		console.log(`Inscrito no tópico: ${topic}`)
	}

	unsubscribe(topic) {
		if (!this.ws) {
			console.error("A conexão WebSocket não foi estabelecida.")
			return
		}

		if (!this.subscriptions[topic]) {
			console.warn(`Não inscrito no tópico: ${topic}`)
			return
		}

		this.subscriptions[topic].close()
		delete this.subscriptions[topic]

		console.log(`Cancelada a inscrição no tópico: ${topic}`)
	}

	loadContacts(topic, payload) {
		if (!this.ws || this.ws?._connectionState !== "open") {
			console.error("A conexão WebSocket não foi estabelecida.")
			return
		}

		if (!this.subscriptions[topic]) {
			console.warn(`Não inscrito no tópico: ${topic}`)
			return
		}

		this.subscriptions[topic].emit("contacts", payload)

		console.log(`Carregando contatos para o tópico: ${topic}`)
	}

	sendMessage(topic, message, event = "message") {
		if (!this.ws || this.ws?._connectionState !== "open") {
			console.error("A conexão WebSocket não foi estabelecida.")
			return
		}

		if (!this.subscriptions[topic]) {
			console.warn(`Não inscrito no tópico: ${topic}`)
			return
		}

		this.subscriptions[topic].emit(event, message)

		console.log(`Mensagem enviada para o tópico: ${topic}`, { event })
	}
	disconnect() {
		if (this.ws) this.ws.close()
	}
}

export default WebSocketClient
