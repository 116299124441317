import React, { useState, useEffect, useContext } from "react";
import EmpresaContext from "../../../contexts/Empresa";
import moment from "moment";
import { usePersistedState } from "../../../hooks";
import api from "../../../services/api";
import { hasPermission } from "../../../utils";

import TagsInput from "react-tagsinput";
import Filters from "../../../components/Headers/Filters";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import Select from "react-select";
import ColaboradorEquipeSelect from "./ColaboradorEquipeSelect";

import InputMask from "react-input-mask";
import SelectDisplayMode from "./SelectDisplayMode";
import SelectPreset from "./SelectPreset";
import {
  CardBody,
  Row,
  Col,
  Input,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Collapse,
  Label,
  ButtonGroup,
} from "reactstrap";

export default ({
  title,
  load,
  history,
  visaoMode,
  setVisaoMode,
  notify,
  ...props
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [limpouDataAgendamento, setLimpouDataAgendamento] = usePersistedState(
    "limpouDataAgendamento",
    false
  );
  const [dataAgendamentoInicial, setDataAgendamentoInicial] = usePersistedState(
    "dataAgendamentoInicial",
    (() => {
      if (!limpouDataAgendamento) {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth());
      }
      return null;
    })()
  );
  const [dataAgendamentoFinal, setDataAgendamentoFinal] = usePersistedState(
    "dataAgendamentoFinal",
    (() => {
      if (!limpouDataAgendamento) return new Date();
      return null;
    })()
  );
  const [dataCadastroInicial, setDataCadastroInicial] = usePersistedState(
    "dataCadastroInicial",
    null
  );
  const [dataCadastroFinal, setDataCadastroFinal] = usePersistedState(
    "dataCadastroFinal",
    null
  );

  const [canais, setCanais] = usePersistedState("canais", []);
  const [campanha, setCampanha] = usePersistedState("campanha", -1);
  const [anunciosSelecionados, setAnunciosSelecionados] = usePersistedState(
    "anunciosSelecionados",
    []
  );
  const [funis, setFunis] = usePersistedState("funis", []);

  const [estagios, setEstagios] = usePersistedState("estagios", []);
  const [expectativa, setExpectativa] = usePersistedState("expectativa", null);
  const [cliente, setCliente] = usePersistedState("cliente", "");
  const [onlyEmAbertas, setOnlyEmAbertas] = usePersistedState(
    "onlyEmAbertas",
    "true"
  );
  const [motivoEncerramento, setMotivoEncerramento] = usePersistedState(
    "motivoEncerramento",
    null
  );
  const [colaboradores, setColaboradores] = usePersistedState(
    "colaboradores",
    []
  );
  const [produtos, setProdutos] = usePersistedState("produtos", []);
  const [tipoProduto, setTipoProduto] = usePersistedState("tipoProduto", null);
  const [contactacao, setContactacao] = usePersistedState("contactacao", "-1");
  const [equipes, setEquipes] = usePersistedState("equipes", []);
  const [tags, setTags] = usePersistedState("tags", []);
  const [pesquisarTelefoneCliente, setPesquisarTelefoneCliente] =
    usePersistedState("pesquisarTelefoneCliente", false);
  const [pesquisarColaboradorCriador, setPesquisarColaboradorCriador] =
    usePersistedState("pesquisarColaboradorCriador", false);

  //Listas para carregar os filtros
  const [canaisList, setCanaisList] = useState([]);
  const [campanhas, setCampanhas] = useState([]);
  const [anuncios, setAnuncios] = useState([]);
  const [funisList, setFunisList] = useState([]);
  const [estagiosList, setEstagiosList] = useState([]);
  const [expectativas, setExpectativas] = useState([]);
  const [motivosEncerramentos, setMotivosEncerramentos] = useState([]);
  const [users, setUsers] = useState([]);
  const [produtosList, setProdutosList] = useState([]);
  const [tiposProdutos, setTipoProdutos] = useState([]);
  const [equipesList, setEquipesList] = useState([]);

  const [unidadesFederativas, setUnidadesFederativas] = useState([]);
  const [UFSelecionada, setUFSelecionada] = usePersistedState("UF", null);

  const [municipios, setMunicipios] = useState([]);
  const [municipioSelecionado, setMunicipioSelecionado] = usePersistedState(
    "Municipio",
    null
  );

  //Flag para definir tempo de execução
  const [runLoad, setRunLoad] = useState(props.search ? props.search : true);
  const [monitorClearFilters, setMonitorClearFilters] = useState(undefined);

  //flag pra chamar o fillEquipes
  const [runFillEquipes, setRunFillEquipes] = useState(true);
  //flag pra chamar o fillMotivoEncerramento
  const [runFillMotivoEncerramento, setRunFillMotivoEncerramento] =
    useState(true);
  //flag pra chamar o fillProdutos
  const [runFillProdutos, setRunFillProdutos] = useState(true);

  const [maisFiltros, setMaisFiltros] = usePersistedState(
    "maisFiltrosOportunidades",
    false
  );

  const [presetEscolhido, setPresetEscolhido] = usePersistedState(
    "presetEscolhido",
    null
  );
  const [activeStage, setActiveStage] = useState(1);
  const [presets, setPresets] = usePersistedState("presets", []);
  const [monitorLoadPreset, setMonitorLoadPreset] = useState(false);
  const [isLoadByPreset, setIsLoadByPreset] = useState(false);
  const [showAnunciosInativos, setShowAnunciosInativos] = useState(false);
  const [showApenasCampanhasAtivas, setShowApenasCampanhasAtivas] =
    useState(true);
  useEffect(() => {
    if (funisList && funisList.length > 0) {
      setFunis([funisList[0].id]); // Seleciona o primeiro funil
    }
  }, [funisList]);

  useEffect(() => {
    if (monitorClearFilters) {
      search(true, true);
    }
  }, [monitorClearFilters]);

  useEffect(() => {
    if (monitorLoadPreset) {
      search(true, false);
    }
  }, [monitorLoadPreset]);

  useEffect(() => {
    handlePesquisar();
  }, [estagios]);


  useEffect(() => {
    if (presets.length === 0) loadPresets();
    if (canaisList.length === 0) loadCanais();
    if (campanhas.length === 0) loadCampanhas();
    if (funisList.length === 0) loadFunis();
    if (estagiosList.length === 0) loadEstagios();
    if (expectativas.length == 0) loadExpectativas();
    if (tiposProdutos.length === 0) loadTiposProdutos();
    if (equipesList.length === 0) loadEquipes();
    if (unidadesFederativas.length === 0) loadUnidadesFederativas();
    //Carregar combos caso o valor esteja na sessão
    if (campanha) loadAnuncios(campanha);
    //Search comentado pra achar duplicidade de consulta inicial
    // await search(false, false)
  }, []);

  useEffect(() => {
    if (runFillEquipes) {
      fillEquipes();
    }

    if (hasPermission("ver-todas-oportunidades")) {
      loadColaboradores();
    }

    setRunFillEquipes(false);
  }, [equipes]);

  useEffect(() => {
    loadProdutos(tipoProduto);
  }, [tipoProduto]);

  useEffect(() => {
    loadEstagios();
  }, [funis]);

  useEffect(() => {
    loadAnuncios(campanha);
  }, [canais]);

  useEffect(() => {
    loadMunicipios(UFSelecionada);
  }, [UFSelecionada]);

  useEffect(() => {
    if (runLoad) {
      //Search comentado pra achar duplicidade de consulta inicial
      search(false, false);
      setRunLoad(false);
    }
  }, [runLoad]);

  useEffect(() => {
    loadEncerramentos();
  }, [onlyEmAbertas]);

  useEffect(() => {
    if (anunciosSelecionados && anunciosSelecionados.length > 0) {
      setAnunciosSelecionados(anunciosSelecionados);
    } else {
      setAnunciosSelecionados([]);
    }
  }, [anuncios]);

  useEffect(() => {
    loadAnuncios(campanha);
  }, [showAnunciosInativos]);

  useEffect(() => {
    loadCampanhas();
  }, [showApenasCampanhasAtivas]);

  useEffect(() => {
    // if (presetEscolhido) {
    //   if (presets && presets.length > 0) {
    //     const preset = presets.find((pre) => pre.id == presetEscolhido.id);
    //   }
    // }
    loadPreset(presetEscolhido);
  }, [presetEscolhido]);

  async function loadPreset(preset) {
    setMonitorLoadPreset(false);
    if (preset) {
      setIsLoadByPreset(true);
      await loadCamposPreset(preset.dados);
      setMonitorLoadPreset(true);
    }
  }

  async function loadCamposPreset(preset) {
    // if (preset.porEstagio) preset = preset.dados;
    loadDatasPreset(
      preset,
      "dataAgendamento",
      setDataAgendamentoInicial,
      setDataAgendamentoFinal
    );
    loadDatasPreset(
      preset,
      "dataCadastro",
      setDataCadastroInicial,
      setDataCadastroFinal
    );
    setCliente(preset.cliente ? preset.cliente : "");
    setPesquisarTelefoneCliente(!!preset.pesquisarTelefoneCliente);

    setCanais(preset.canais ? preset.canais : []);
    setCampanha(preset.campanha ? preset.campanha : "-1");
    setAnunciosSelecionados(preset.anuncios ? preset.anuncios : []);

    setFunis(preset.funis ? preset.funis : []);
    setEstagios(preset.estagios ? preset.estagios : []);
    setExpectativa(preset.expectativa ? preset.expectativa : null);
    setRunFillMotivoEncerramento(true);
    setMotivoEncerramento(
      preset.motivoEncerramento ? preset.motivoEncerramento : null
    );
    const isFavoritoVenda = preset?.isNested && preset.estagios[0] == "3";
    setOnlyEmAbertas(isFavoritoVenda ? false : preset.onlyEmAbertas);

    setTags(preset.tags ? preset.tags : []);
    setRunFillProdutos(true);
    setProdutos(preset.produtos ? preset.produtos : []);
    setTipoProduto(preset.tipoProduto ? preset.tipoProduto : null);

    setContactacao(preset.onlyContactadas);
    setRunFillEquipes(true);
    setColaboradores(preset.colaboradores ? preset.colaboradores : []);
    setEquipes(preset.equipes ? preset.equipes : []);
    setPesquisarColaboradorCriador(!!preset.pesquisarColaboradorCriador);
  }

  function loadDatasPreset(preset, tipoData, setDataInicial, setDataFinal) {
    // get current date
    var hoje = new Date();
    let dataInicial = null;
    let dataFinal = null;

    let periodoConverter = null;

    switch (tipoData) {
      case "dataAgendamento":
        periodoConverter = preset.dataAgendamento;
        break;
      case "dataCadastro":
        periodoConverter = preset.dataCadastro;
        break;
      case "dataEncerramento":
        periodoConverter = preset.dataEncerramento;
        break;
    }

    switch (periodoConverter) {
      case "diario":
        dataInicial = hoje;
        dataFinal = hoje;
        break;
      case "semanal":
        // First day is the day of the month - the day of the week
        var firstDayWeek = hoje.getDate() - hoje.getDay();
        // last day is the first day + 6
        var lastDayWeek = firstDayWeek + 6;
        dataInicial = new Date(hoje.setDate(firstDayWeek)).toUTCString();
        dataFinal = new Date(hoje.setDate(lastDayWeek)).toUTCString();
        break;
      case "quinzenal":
        var day16 = new Date(hoje.getFullYear(), hoje.getMonth(), 16);
        if (hoje >= day16) {
          dataInicial = new Date(hoje.getFullYear(), hoje.getMonth(), 16);
          dataFinal = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);
        } else {
          dataInicial = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
          dataFinal = new Date(hoje.getFullYear(), hoje.getMonth(), 15);
        }
        break;
      case "mensal":
        dataInicial = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
        dataFinal = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);
        break;
      case "trimestral":
        var inicioPrimeiroTrimestre = new Date(hoje.getFullYear(), 0, 1);
        var inicioSegundoTrimestre = new Date(hoje.getFullYear(), 3, 1);
        var inicioTerceiroTrimestre = new Date(hoje.getFullYear(), 6, 1);
        var inicioQuartoTrimestre = new Date(hoje.getFullYear(), 9, 1);

        if (hoje >= inicioQuartoTrimestre) {
          dataInicial = inicioQuartoTrimestre;
          dataFinal = new Date(
            inicioQuartoTrimestre.getFullYear(),
            inicioQuartoTrimestre.getMonth() + 3,
            0
          );
        } else {
          if (hoje >= inicioTerceiroTrimestre) {
            dataInicial = inicioTerceiroTrimestre;
            dataFinal = new Date(
              inicioTerceiroTrimestre.getFullYear(),
              inicioTerceiroTrimestre.getMonth() + 3,
              0
            );
          } else {
            if (hoje >= inicioSegundoTrimestre) {
              dataInicial = inicioSegundoTrimestre;
              dataFinal = new Date(
                inicioSegundoTrimestre.getFullYear(),
                inicioSegundoTrimestre.getMonth() + 3,
                0
              );
            } else {
              dataInicial = inicioPrimeiroTrimestre;
              dataFinal = new Date(
                inicioPrimeiroTrimestre.getFullYear(),
                inicioPrimeiroTrimestre.getMonth() + 3,
                0
              );
            }
          }
        }

        break;
      case "semestral":
        var inicioPrimeiroSemestre = new Date(hoje.getFullYear(), 0, 1);
        var inicioSegundoSemestre = new Date(hoje.getFullYear(), 6, 1);

        if (hoje >= inicioSegundoSemestre) {
          dataInicial = inicioSegundoSemestre;
          dataFinal = new Date(
            inicioSegundoSemestre.getFullYear(),
            inicioSegundoSemestre.getMonth() + 6,
            0
          );
        } else {
          dataInicial = inicioPrimeiroSemestre;
          dataFinal = new Date(
            inicioPrimeiroSemestre.getFullYear(),
            inicioPrimeiroSemestre.getMonth() + 6,
            0
          );
        }
        break;
      case "anual":
        dataInicial = new Date(hoje.getFullYear(), 0, 1);
        dataFinal = new Date(hoje.getFullYear(), 11, 31);
        break;
      case "ateontem":
        dataFinal = new Date(hoje.getTime() - 60000);
        break;
    }

    setDataInicial(dataInicial);
    setDataFinal(dataFinal);
  }

  async function handlePesquisar() {
    await search(true, false);
  }

  async function search(clearPagination, clearSort) {
    await load(
      {
        dataCadastroInicial,
        dataCadastroFinal,
        dataAgendamentoInicial,
        dataAgendamentoFinal,
        canais,
        campanha,
        anuncios: anunciosSelecionados,
        //estagio: estagios,
        funis,
        estagios,
        expectativa,
        cliente,
        pesquisarTelefoneCliente,
        onlyEmAbertas,
        motivoEncerramento,
        colaboradores,
        tipoProduto,
        produtos,
        tags,
        equipes: equipes && equipes.length > 0 ? equipes : [-1],
        onlyContactadas: contactacao,
        pesquisarColaboradorCriador,
        UFSelecionada: UFSelecionada == -1 ? null : UFSelecionada,
        municipioSelecionado:
          municipioSelecionado == -1 ? null : municipioSelecionado,
      },
      clearPagination,
      clearSort
    );

    setMonitorLoadPreset(false);
    setIsLoadByPreset(false);
  }

  function handleLimparDataAgendamento() {
    setDataAgendamentoFinal(null);
    setDataAgendamentoInicial(null);
    setLimpouDataAgendamento(true);
  }

  function handleLimparDataCadastro() {
    setDataCadastroInicial(null);
    setDataCadastroFinal(null);
  }

  async function handleClearFilter() {
    setLimpouDataAgendamento(false);
    setDataAgendamentoInicial(
      (() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth());
      })()
    );
    setDataAgendamentoFinal(new Date());
    setDataCadastroInicial(null);
    setDataCadastroFinal(null);
    setCanais([]);
    setCampanha(-1);
    setAnunciosSelecionados([]);
    setAnuncios([]);
    setFunis([]);
    setEstagios([]);
    setExpectativa(null);
    setCliente("");
    setPesquisarTelefoneCliente(false);
    setOnlyEmAbertas("true");
    setMotivoEncerramento(null);

    setProdutos([]);
    setTipoProduto(null);

    setUsers([]);
    setTags([]);
    setContactacao("-1");

    setColaboradores([]);
    setRunFillEquipes(true);
    setEquipes(null);

    setPresetEscolhido(null);

    setUFSelecionada(null);
    setMunicipioSelecionado(null);
  }

  async function loadPresets() {
    try {
      const response = await api.get(
        `common/empresas/${empresaSelecionada.id}/presets_oportunidades`
      );
      if (response.data) {
        setPresets(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /* Adiciona todos os itens em <code>equipeList</code> para equipes, se não houver equipe selecionada na sessão*/
  function fillEquipes() {
    setRunFillEquipes(false);
    if (equipes && equipes.length > 0) {
      setEquipes(equipes);
    } else {
      setEquipes(equipesList.map((item) => item.id));
    }
  }

  async function loadUnidadesFederativas() {
    try {
      const { data } = await api.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );
      setUnidadesFederativas(data);

      if (data) {
        data.unshift({ id: -1, nome: "- Todas -" });
        setUnidadesFederativas(data);
      }

    } catch (error) {
      console.error(
        "Ocorreu um erro ao buscar informações das UFs e cidades. ",
        error
      );
    }
  }

  async function loadMunicipios(estadoSelecionadoId) {
    try {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoSelecionadoId}/municipios`
      );

      if (data) {
        data.unshift({ id: -1, nome: "- Todas -" });
        setMunicipios(data);
      }

      setMunicipios(data);
    } catch (error) {
      console.error(
        "Ocorreu um erro ao buscar informações das UFs e cidades. ",
        error
      );
    }
  }

  async function loadEquipes() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada.id}/equipes`
      );
      const data = await response.data;
      if (data.length > 0) {
        setEquipesList(data);
        if (runFillEquipes) {
          fillEquipes();
        }
        setRunFillEquipes(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadTiposProdutos() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada.id}/tipos-produtos?situacao=A`
      );

      if (response.data) {
        response.data.unshift({ id: -1, nome: "- Todos -" });
        setTipoProdutos(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadProdutos(tipoProduto) {
    if (tipoProduto && tipoProduto == -1) {
      tipoProduto = null;
    }
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada.id}/produtos/`,
        {
          params: {
            situacao: "A",
            limit: 1000,
            tipoProduto,
          },
        }
      );
      const data = await response.data.data;
      setProdutosList(data);
      if (produtos && produtos.length > 0 && runFillProdutos) {
        setProdutos(produtos);
      } else {
        setProdutos([]);
      }
      setRunFillProdutos(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadEncerramentos() {
    try {
      const aplicacoes = [];
      switch (onlyEmAbertas) {
        case "true":
          aplicacoes.push("abertura");
          aplicacoes.push("null");
          break;
        case "false":
          aplicacoes.push("encerramento");
          aplicacoes.push("null");
          break;
        default:
          break;
      }
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/motivos-encerramentos`,
        {
          params: { aplicacoes },
        }
      );
      if (response.data) {
        response.data.unshift({ id: -1, nome: "- Todos -" });
        setMotivosEncerramentos(response.data);
        if (motivoEncerramento && runFillMotivoEncerramento) {
          setMotivoEncerramento(motivoEncerramento);
        } else {
          setMotivoEncerramento(null);
        }
        setRunFillMotivoEncerramento(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadColaboradores() {
    try {
      if (!isLoadByPreset) {
        setColaboradores([]);
      }
      const response = await api.get(
        `common/empresas/${empresaSelecionada.id}/colaboradores`,
        {
          params: {
            roles: ["comercial", "relacionamento"],
            equipes: equipes && equipes.length > 0 ? equipes : [-1],
          },
        }
      );
      let listaColaboradores = response.data;
      setUsers(listaColaboradores);

      if (colaboradores && colaboradores.length > 0) {
        //valida se os colaboradores selecionados ainda fazem parte das novas equipes selecionadas
        const colabsValidos = [];
        for (const colab of listaColaboradores) {
          for (const idColab of colaboradores) {
            if (idColab == colab.id) {
              colabsValidos.push(idColab);
            }
          }
        }
        setColaboradores(colabsValidos);
      } else {
        setColaboradores([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadCanais() {
    try {
      const response = await api.get(`/marketing/canais`);
      setCanaisList(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar canais");
    }
  }

  async function loadCampanhas() {
    const situ = `?situacao=${showApenasCampanhasAtivas ? "ativo" : null}`;
    const response = await api.get(
      `marketing/campanha/${empresaSelecionada.id}${showApenasCampanhasAtivas ? situ : ""
      }`
    );

    if (response.data) {
      response.data.unshift({ id: -1, nome: "- Todas -" });
      setCampanhas(response.data);
    }
  }

  async function loadAnuncios(campanha_id) {
    setCampanha(campanha_id);

    if (campanha_id) {
      var response = null;

      response = await api.get(
        `marketing/anuncios/${campanha_id}/${empresaSelecionada.id}`,
        {
          params: {
            situacao: showAnunciosInativos ? null : "ativo",
            canais,
            orderFild: "nome",
            order: "asc",
          },
        }
      );

      setAnuncios(await response.data);
      if (anunciosSelecionados && anunciosSelecionados.length > 0) {
        setAnunciosSelecionados(anunciosSelecionados);
      } else {
        setAnunciosSelecionados([]);
      }
    }
  }

  async function loadEstagios() {
    setEstagiosList([]);
    var response = null;

    if (funis && funis.length > 0) {
      response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/estagios/getbyfunis`,
        {
          params: {
            funis,
          },
        }
      );
    } else {
      response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/estagios`
      );
    }

    if (response && response.data) {
      setEstagiosList(response.data);
    }
  }

  async function loadFunis() {
    const response = await api.get(
      `common/empresas/${empresaSelecionada?.id}/funis`
    );
    if (response.data) {
      //response.data.unshift({ id: -1, nome: '- Todos -' })
      setFunisList(response.data);
    }
  }

  async function loadExpectativas() {
    const response = await api.get(`marketing/expectativas`);
    if (response.data) {
      response.data.unshift({ id: -1, nome: "- Todos -" });
      setExpectativas(response.data);
    }
  }

  const handleTags = (tagsinput) => {
    if (!tagsinput) return;
    const newTags = tagsinput.map((tag) => tag.trim().toLowerCase());
    let unique = [...new Set(newTags)];
    setTags(unique);
  };

  function handleMultipleSelect(target, state) {
    const array = Array.from(target.selectedOptions);
    state(array.map((item) => item.value));
  }

  const estagioSelectStyles = {
    container: (provided) => ({ ...provided, flex: 1 }),
    control: (provided) => ({ ...provided, minHeight: "46px" }),
  };

  return (
    <>
      <Filters
        title={title}
        displayMode={() => SelectDisplayMode(visaoMode, setVisaoMode)}
        presets={() =>
          SelectPreset(
            presetEscolhido,
            setPresetEscolhido,
            presets,
            estagiosList
          )
        }
        onSearch={handlePesquisar}
        clearFilters={handleClearFilter}
        onFiltersClead={setMonitorClearFilters}
        monitorLoadPreset={monitorLoadPreset}
      >
        <div>
          <Row style={{ padding: 4 }}>
            <Col xs="12" lg="6" sm="6" md="12">
              <div
                style={{
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: "#eee",
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: 13,
                }}
              >




                <FormGroup style={{ flex: 1, paddingRight: 4 }}>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Agendamento inicial
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Data inicial",
                        style: {
                          width: "100px",
                        },
                      }}
                      value={moment(dataAgendamentoInicial)}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          dataAgendamentoInicial &&
                          dataAgendamentoFinal &&
                          dataAgendamentoInicial._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          dataAgendamentoInicial &&
                          dataAgendamentoFinal &&
                          new Date(dataAgendamentoInicial._d + "") <
                          new Date(currentDate._d + "") &&
                          new Date(dataAgendamentoFinal._d + "") >
                          new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          dataAgendamentoFinal &&
                          dataAgendamentoFinal._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) => setDataAgendamentoInicial(e)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup style={{ flex: 1, paddingLeft: 4 }}>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Agendamento final
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Data final",
                        style: {
                          width: "100px",
                        },
                      }}
                      style={{ width: "100px" }}
                      value={moment(dataAgendamentoFinal)}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          dataAgendamentoInicial &&
                          dataAgendamentoFinal &&
                          dataAgendamentoInicial._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          dataAgendamentoInicial &&
                          dataAgendamentoFinal &&
                          new Date(dataAgendamentoInicial._d + "") <
                          new Date(currentDate._d + "") &&
                          new Date(dataAgendamentoFinal._d + "") >
                          new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          dataAgendamentoFinal &&
                          dataAgendamentoFinal._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) => setDataAgendamentoFinal(e)}
                    />
                  </InputGroup>
                </FormGroup>
                <Button
                  className="ml-2"
                  color="secondary"
                  outline
                  title="Limpar datas de agendamento"
                  size="sm"
                  onClick={handleLimparDataAgendamento}
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-trash" />
                  </span>
                </Button>
              </div>
            </Col>
            <Col xs="12" lg="6" sm="6" md="12">
              <div
                style={{
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: "#eee",
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: 13,
                }}
              >
                <FormGroup style={{ flex: 1, paddingRight: 4 }}>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Cadastro inicial
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Data inicial",
                        style: {
                          width: "100px",
                        },
                      }}
                      value={moment(dataCadastroInicial)}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          dataCadastroInicial &&
                          dataCadastroFinal &&
                          dataCadastroInicial._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          dataCadastroInicial &&
                          dataCadastroFinal &&
                          new Date(dataCadastroInicial._d + "") <
                          new Date(currentDate._d + "") &&
                          new Date(dataCadastroFinal._d + "") >
                          new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          dataCadastroFinal &&
                          dataCadastroFinal._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) => setDataCadastroInicial(e)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup style={{ flex: 1, paddingLeft: 4 }}>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Cadastro final
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Data final",
                        style: {
                          width: "100px",
                        },
                      }}
                      style={{ width: "100px" }}
                      value={moment(dataCadastroFinal)}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          dataCadastroInicial &&
                          dataCadastroFinal &&
                          dataCadastroInicial._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          dataCadastroInicial &&
                          dataCadastroFinal &&
                          new Date(dataCadastroInicial._d + "") <
                          new Date(currentDate._d + "") &&
                          new Date(dataCadastroFinal._d + "") >
                          new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          dataCadastroFinal &&
                          dataCadastroFinal._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) => setDataCadastroFinal(e)}
                    />
                  </InputGroup>
                </FormGroup>
                <Button
                  className="ml-2"
                  color="secondary"
                  outline
                  title="Limpar datas de cadastro"
                  size="sm"
                  onClick={handleLimparDataCadastro}
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-trash" />
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col xs="12" lg="6" sm="12" md="6">
              <FormGroup>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <label className="form-control-label">Cliente</label>
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="check-telefone"
                      type="checkbox"
                      checked={pesquisarTelefoneCliente}
                      onChange={() =>
                        setPesquisarTelefoneCliente(!pesquisarTelefoneCliente)
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="check-telefone"
                    >
                      Pesquisar por telefone
                    </label>
                  </div>
                </div>
                <InputMask
                  placeholder={
                    pesquisarTelefoneCliente
                      ? "Telefone do cliente..."
                      : "Nome ou CPF/CNPJ do cliente..."
                  }
                  className="form-control"
                  maskPlaceholder={null}
                  mask={pesquisarTelefoneCliente ? "99 99999-9999" : undefined}
                  value={cliente}
                  onChange={(e) => setCliente(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col xs="12" lg="6" sm="12" md="6">
              <FormGroup>
                <label className="form-control-label">Tags</label>
                <div
                  style={{
                    minHeight: "calc(2.75rem + 2px)",
                    border: "1px solid #DEE2E6",
                    padding: 4,
                    borderRadius: 4,
                  }}
                >
                  <TagsInput
                    className="bootstrap-tagsinput"
                    onChange={handleTags}
                    tagProps={{ className: "tag badge badge-primary mr-1" }}
                    value={tags}
                    inputProps={{
                      className: "",
                      placeholder: "Adicionar tags...",
                    }}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: -20 }}>
            <Col xs="12" lg="12" sm="12" md="12">
              <Button
                style={{ display: "flex", padding: 0, color: "#4385b1" }}
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setMaisFiltros(!maisFiltros);
                }}
              >
                {maisFiltros ? "menos filtros (-)" : "mais filtros (+)"}
              </Button>
            </Col>
          </Row>

          <Collapse isOpen={maisFiltros}>
            <Row className="py-4">
              <Col xs="12" lg="4" sm="12" md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Canais
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      multiple
                      onSelect={({ target }) =>
                        handleMultipleSelect(target, setCanais)
                      }
                      onUnselect={({ target }) =>
                        handleMultipleSelect(target, setCanais)
                      }
                      options={{
                        placeholder: "Selecione...",
                      }}
                      value={canais}
                      data={canaisList.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="4" sm="12" md="6">
                <FormGroup>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      className="form-control-label"
                      htmlFor="example-number-input"
                    >
                      Campanha
                    </label>
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="check-ativos"
                        checked={showApenasCampanhasAtivas}
                        onChange={() =>
                          setShowApenasCampanhasAtivas(
                            !showApenasCampanhasAtivas
                          )
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="check-ativos"
                      >
                        Exibir somente ativas
                      </label>
                    </div>
                  </div>

                  <InputGroup className="input-group-alternative">
                    <Select2
                      defaultValue="-1"
                      onSelect={(e) => loadAnuncios(e.target.value)}
                      options={{
                        placeholder: "Selecione uma campanha...",
                      }}
                      value={campanha}
                      data={campanhas.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="4" sm="12" md="6">
                <FormGroup>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      className="form-control-label"
                      htmlFor="example-number-input"
                    >
                      Anúncios
                    </label>
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="check-inativos"
                        checked={showAnunciosInativos}
                        onChange={() =>
                          setShowAnunciosInativos(!showAnunciosInativos)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="check-inativos"
                      >
                        Incluir inativos
                      </label>
                    </div>
                  </div>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      // className="form-control"
                      multiple
                      onSelect={({ target }) =>
                        handleMultipleSelect(target, setAnunciosSelecionados)
                      }
                      onUnselect={({ target }) =>
                        handleMultipleSelect(target, setAnunciosSelecionados)
                      }
                      options={{
                        placeholder: "Selecione os anúncios...",
                      }}
                      value={anunciosSelecionados}
                      data={anuncios.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="3" sm="12" md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Funil
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2

                      onSelect={({ target }) =>
                        handleMultipleSelect(target, setFunis)
                      }
                      onUnselect={({ target }) =>
                        handleMultipleSelect(target, setFunis)
                      }
                      options={{
                        placeholder: "Selecione...",
                      }}
                      value={funis}
                      data={funisList.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="2" sm="12" md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Estágio
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select
                      placeholder=""
                      isMulti
                      name="estagio"
                      styles={estagioSelectStyles}
                      onChange={(values) => {
                        setEstagios(values.map((e) => e.value));
                      }}
                      options={estagiosList.map((item) => ({
                        value: item.id,
                        label: item.nome,
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={estagiosList
                        .map((item) => ({
                          value: item.id,
                          label: item.nome,
                        }))
                        .filter((item) => estagios.includes(item.value))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="2" sm="12" md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Expectativa
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      defaultValue="-1"
                      options={{
                        placeholder: "Selecione...",
                      }}
                      onSelect={(e) => setExpectativa(e.target.value)}
                      value={expectativa}
                      data={expectativas.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="2" sm="12" md="3">
                <FormGroup>
                  <label className="form-control-label">Situação</label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      defaultValue="-1"
                      options={{
                        placeholder: "Selecione...",
                      }}
                      onSelect={(e) => setOnlyEmAbertas(e.target.value)}
                      value={onlyEmAbertas}
                      data={[
                        {
                          id: -1,
                          text: "- Todos -",
                        },
                        {
                          id: true,
                          text: "Em aberto",
                        },
                        {
                          id: false,
                          text: "Encerrados",
                        },
                      ]}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="3" sm="12" md="3">
                <FormGroup>
                  <label className="form-control-label">
                    Motivo de encerramento ou checagem
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      defaultValue="-1"
                      options={{
                        placeholder: "Selecione...",
                      }}
                      onSelect={(e) => setMotivoEncerramento(e.target.value)}
                      value={motivoEncerramento}
                      data={motivosEncerramentos.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="12" lg="3" sm="12" md="12">
                <FormGroup>
                  <label className="form-control-label">Tipo de produto</label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      defaultValue="-1"
                      options={{
                        placeholder: "Selecione...",
                      }}
                      onSelect={({ target }) => setTipoProduto(target.value)}
                      value={tipoProduto}
                      data={tiposProdutos.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="4" sm="12" md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Produtos
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      multiple
                      onSelect={({ target }) =>
                        handleMultipleSelect(target, setProdutos)
                      }
                      onUnselect={({ target }) =>
                        handleMultipleSelect(target, setProdutos)
                      }
                      options={{
                        placeholder: "Selecione...",
                      }}
                      value={produtos}
                      data={produtosList.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="2" sm="12" md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Unidade Federativa
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      defaultValue="-1"
                      options={{
                        placeholder: "Selecione...",
                      }}
                      // multiple
                      // onSelect={(e) =>
                      //   handleMultipleSelect(e.target, setUFSelecionada)
                      // }
                      // onUnselect={(e) =>
                      //   handleMultipleSelect(e.target, setUFSelecionada)
                      // }
                      onSelect={({ target }) => setUFSelecionada(target.value)}
                      value={UFSelecionada}
                      data={unidadesFederativas?.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="3" sm="12" md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Cidade
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      defaultValue="-1"
                      options={{
                        placeholder: "Selecione...",
                      }}
                      // multiple
                      // onSelect={(e) =>
                      //   handleMultipleSelect(e.target, setMunicipioSelecionado)
                      // }
                      // onUnselect={(e) =>
                      //   handleMultipleSelect(e.target, setMunicipioSelecionado)
                      // }
                      onSelect={({ target }) =>
                        setMunicipioSelecionado(target.value)
                      }
                      value={municipioSelecionado}
                      data={municipios?.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="12" lg="4" sm="12" md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Contactação
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      defaultValue="-1"
                      options={{
                        placeholder: "Selecione...",
                      }}
                      onSelect={({ target }) => setContactacao(target.value)}
                      value={contactacao}
                      data={[
                        { id: "true", text: "Contactados" },
                        { id: "false", text: "Não contactados" },
                        { id: "-1", text: "Contactados e não contactados" },
                      ]}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              {/* <ColaboradorSelect /> */}
              <ColaboradorEquipeSelect
                hasPermission={hasPermission}
                equipes={equipes}
                setEquipes={setEquipes}
                equipesList={equipesList}
                handleMultipleSelect={handleMultipleSelect}
                pesquisarColaboradorCriador={pesquisarColaboradorCriador}
                setPesquisarColaboradorCriador={setPesquisarColaboradorCriador}
                colaboradores={colaboradores}
                setColaboradores={setColaboradores}
                users={users}
              />
            </Row>
          </Collapse>
        </div>
      </Filters>

      {/* //TODO: Reposicionar e tornar filtro funcional. */}
      <ButtonGroup className="w-100 py-4 flex-wrap" style={{ fontSize: "14px", rowGap: "1rem" }}>
        {estagiosList.map((estagio, index) => (
          <Button
            key={index}
            color="light"
            style={{
              flex: "1",
              minWidth: "200px",
              maxWidth: "20%",
              fontSize: ".85rem",
              fontWeight: "bold",
              color: estagios.includes(estagio.id) ? "white" : "#6c757d",
              backgroundColor: estagios.includes(estagio.id) ? "#0847D6" : "#f8f9fa",
              position: "relative",
              clipPath:
                index === 0
                  ? "polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%)"
                  : index === estagiosList.length - 1
                    ? "polygon(0 0, 100% 0, 100% 100%, 0 100%, 5% 50%)"
                    : "polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%, 5% 50%)",
              border: "none",
            }}
            onClick={() => {

              if (estagios.includes(estagio.id)) {
                setEstagios(estagios.filter((id) => id !== estagio.id));
              } else {
                setEstagios([...estagios, estagio.id]);
              }

            }}
          >

            {estagio.nome}
          </Button>
        ))}
      </ButtonGroup>

    </>
  );
};
