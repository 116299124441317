import React from "react"
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button, Badge, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { IAccount } from "../../../../../../marketing/Chat/types"

interface ContaSocialProps {
	disabled?: boolean
	name: string
	setTabActive: () => void
	title: string
	icon: string
	isConnected?: boolean
	accounts?: IAccount[]
	type?: "email" | "phone" | "portais"
}

export default function ContaSocialCard({
	disabled,
	name,
	setTabActive,
	title,
	icon,
	isConnected = false,
	accounts,
	type,
}: ContaSocialProps) {
	const content = () => {
		if (accounts?.length! > 0) {
			return (
				<>
					<Badge pill color="success">
						{accounts?.length}
						{type === "email" && " email(s) conectado(s)"}
						{type === "phone" && " número(s) conectado(s)"}
						{type === "portais" && " portal(s) conectado(s)"}
					</Badge>
					<Button size="sm" onClick={setTabActive} color="info">
						Detalhes
					</Button>
				</>
			)
		} else {
			return (
				<>
					{!isConnected && (
						<Badge pill color="warning">
							Desconectado
						</Badge>
					)}
					{!isConnected && (
						<Button size="sm" onClick={setTabActive} color="primary">
							Conectar
						</Button>
					)}
					{isConnected && (
						<Badge pill color="success">
							Conectado
						</Badge>
					)}
					{isConnected && (
						<Button size="sm" onClick={setTabActive} color="info">
							Detalhes
						</Button>
					)}
				</>
			)
		}
	}

	return (
		<NavItem style={{ width: "clamp(175px, 100%, 250px)" }}>
			<NavLink href="#" role="tab" disabled={disabled} style={{ cursor: "default" }}>
				<Card className="d-relative">
					<div style={{ top: "8px", right: "8px", position: "absolute" }}>
						<span>
							<i className="fas fa-info-circle"></i>
						</span>
					</div>
					<CardBody className="d-flex flex-column align-items-center" style={{ gap: "10px" }}>
						<div>
							<span style={{ fontSize: "3rem" }}>
								<i className={icon}></i>
							</span>
						</div>
						<CardTitle tag="h4">{title}</CardTitle>
						{content()}
					</CardBody>
				</Card>
			</NavLink>
		</NavItem>
	)
}
