import React, { useState } from "react";
import { Button, Card, CardBody, CardText, Col, Tooltip } from "reactstrap";
import { CanalContato } from "..";
import { GoogleIcon } from "./GoogleIcon";

interface CanalCardProps extends CanalContato {
  onDelete: (canalId: string | number) => void;
  onChangePrivacy: (canalId: string | number, isVisible: boolean) => void;
  isConversasSync: boolean;
  isEmailSync: boolean;
  googleLogin: () => void;
  openWAModal: () => void;
}

export function CanalCard({
  canal,
  identificador,
  isEmailSync,
  onDelete,
  isConversasSync,
  openWAModal,
}: CanalCardProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };
  return (
    <Col xs={12} sm={6} md={4} lg={3} key={canal.id}>
      <Card
        style={{
          background: canal?.color || "#eee",
          height: "400px",
        }}
      >
        <CardBody className="d-flex align-items-center justify-content-center flex-column">
          <CardText
            className="font-weight-bold"
            style={{
              fontSize: "2rem",
            }}
          >
            <span>
              <i className={canal?.icon || "fas fa-portrait"} />
            </span>
          </CardText>
          <CardText className="font-weight-bold text-center">
            {canal?.nome}
          </CardText>
          <CardText>
            {canal.id == 25 ? (
              formatPhoneNumber(identificador)
            ) : (
              <span>{identificador}</span>
            )}
          </CardText>
          <Button
            style={{ position: "absolute", top: "8px", right: "8px" }}
            close
            onClick={() => onDelete(canal?.id)}
          />

          {canal.id == 25 && (
            <>
              <Button size="sm" onClick={openWAModal}>
                {!isConversasSync
                  ? "Sincronizar com o Playnee Conversas"
                  : "Detalhes"}{" "}
                <span className="mt-2">
                  <i className="fas fa-comments" />
                </span>
              </Button>
            </>
          )}

          {canal.id == 12 && isEmailSync ? (
            <>
              <span
                id="emailSync"
                style={{
                  display: "block",
                  background: "white",
                  padding: "4px",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <GoogleIcon />
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-4px",
                    color: "green",
                    fontSize: "0.75rem",
                  }}
                >
                  <i className="fas fa-check"></i>
                </span>
              </span>
              <Tooltip isOpen={tooltipOpen} target="emailSync" toggle={toggle}>
                Email Google sincronizado!
              </Tooltip>
            </>
          ) : null}
        </CardBody>
      </Card>
    </Col>
  );
}
