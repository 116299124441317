import "./styles.css"

import React, { useRef, useState } from "react"
import Select from "react-select"
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap"

import { useWhatsappContext } from "../../../contexts/Whatsapp"
import { useInactivity } from "../../../hooks"
import MsgBox from "./components/MsgBox"
import Offcanvas from "./components/Offcanvas"
import PanelChat from "./components/PanelChat"
import WhatsAppBusinessIcon from "./components/Icons/wabusiness"
import WhatsAppIcon from "./components/Icons/whatsapp"
import WhatsAppVerifiedIcon from "./components/Icons/whatsapp-verified"
import WhatsAppUserIcon from "./components/Icons/whatsapp-user"

export default function Chat() {
	const { accounts, selectedAccount, connectionHasError, selectAccount } = useWhatsappContext()

	const [isOpen, setIsOpen] = useState(false)
	const panelChatScrollRef = useRef<any>(null)

	const customStyles = {
		container: (provided) => ({ ...provided, flex: 1 }),
		option: (provided) => ({ ...provided, zIndex: 100, fontSize: "13px" }),
		menu: (provided) => ({ ...provided, zIndex: 100 }),
		control: (provided) => ({
			...provided,
			minHeight: "36px",
			fontSize: "13px",
		}),
	}

	// Recarrega a tela por inatividade (manter conexão WebSocket)
	useInactivity(300000)

	if (!selectedAccount) return <></>

	return (
		<>
			<div className="chat__page">
				<Select
					placeholder="Selecione a Conta"
					styles={customStyles}
					defaultValue={{
						value: selectedAccount.id,
						label: selectedAccount.verified_name,
						isMeta: selectedAccount.api_meta,
						isUser: selectedAccount.user_id,
					}}
					onChange={(item) => {
						selectAccount(item!.value!)
					}}
					options={accounts?.map((item) => ({
						value: item.id,
						label: item.verified_name,
						isMeta: item.api_meta,
						isUser: item.user_id,
					}))}
					formatOptionLabel={(option) => (
						<div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
							{option.isMeta ? (
								<WhatsAppVerifiedIcon />
							) : option.isUser ? (
								<WhatsAppUserIcon />
							) : (
								<WhatsAppIcon />
							)}

							{option.label}
						</div>
					)}
				/>
			</div>

			<div className="bg-info header-chat chat__page-header--bg"></div>

			<Container className="chat__page-container" fluid>
				<Card>
					<CardBody style={{ paddingBottom: ".75rem" }}>
						<Row className="chat__page-container--inner">
							<div className="chat__page-panelchat col-2 col-sm-3" ref={panelChatScrollRef}>
								<PanelChat panelChatScrollRef={panelChatScrollRef} />
							</div>

							<Col style={{ maxHeight: "100%" }} className="d-flex flex-column">
								<MsgBox />
							</Col>

							<Offcanvas
								toggle={() => setIsOpen(!isOpen)}
								onClose={() => setIsOpen(false)}
								isOpen={isOpen}
							/>
						</Row>
					</CardBody>
				</Card>
			</Container>

			{connectionHasError && (
				<div className="chat__page-alert--container">
					<Alert color="warning" className="chat__page-alert--element">
						Parece que você ficou algum tempo sem utilizar o Playnee Conversas. Clique para atualizar sua
						conexão:
						<Button size="sm" className="ml-1" onClick={() => window.location.reload()}>
							<span>
								<i className="fas fa-sync-alt"></i>
							</span>
						</Button>
					</Alert>
				</div>
			)}
		</>
	)
}
