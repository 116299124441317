import React, { useState, useContext, useEffect, SyntheticEvent } from "react"

import {
	Modal,
	Button,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	InputGroup,
	FormGroup,
	Label,
	Spinner,
} from "reactstrap"
import api from "../../../../../../../services/api"
import Select from "react-select"
import EmpresaContext from "../../../../../../../contexts/Empresa"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Select2 from "react-select2-wrapper"

interface IPortaisModal {
	show: boolean
	toggle: () => void
}

interface Sistema {
	id: number
	nome: string
	tipo: string
	hash: string
	app_id?: string
	scope: string
}

export const PortaisModal: React.FC<IPortaisModal> = ({ show, toggle }) => {
	const [valor, setValor] = useState<string | undefined>(undefined)
	const [sistemas, setSistemas] = useState<Sistema[]>()
	const [selectedIntegration, setSelectedIntegration] = useState<Sistema>()
	const [equipes, setEquipes] = useState<any>([])
	const [equipe, setEquipe] = useState(null)

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [alert, setAlert] = useState<any>(null)

	const { empresaSelecionada } = useContext(EmpresaContext)

	useEffect(() => {
		loadEquipes()
	}, [])

	async function loadEquipes() {
		setEquipe(null)
		try {
			const response = await api.get(`common/empresas/${empresaSelecionada?.id}/equipes?captadora=A`)
			const list = await response.data
			//carrega apenas as equipes que não foram adicionadas a campanha
			setEquipes(list)
		} catch (error) {
			console.error(error)
		}
	}

	const getSistemas = async () => {
		const response = await api.get(`esb/integracoes/sistemas?types[]=text&types[]=oauth&scope=E`)

		setSistemas(response.data)
	}

	function handleClose() {
		setValor("")
		setSelectedIntegration(undefined)
		toggle()
	}

	const handleAdCreate = async (hash: string) => {
		try {
			await api.post(`esb/integracoes/create-ad/${hash}`, { equipes: [+equipe!] })
		} catch (err) {
			console.log(err)
		}
	}

	const handleIntegrateNoOauth = async (hash: string) => {
		try {
			setIsLoading(true)

			await handleAdCreate(hash)

			const response = await api.post(`esb/integracoes/auth/text/${hash}`, {
				value: valor,
				empresaId: empresaSelecionada?.id,
			})

			handleClose()
			setIsLoading(false)

			setAlert(
				<ReactBSAlert
					success
					style={{ display: "block" }}
					title="Sucesso!"
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnBsStyle="primary"
					confirmBtnText="Ok"
					btnSize=""
				>
					{response.data.message}
				</ReactBSAlert>
			)
		} catch (err) {
			handleClose()
			setIsLoading(false)

			setAlert(
				<ReactBSAlert
					error
					style={{ display: "block" }}
					title="Falha!"
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnBsStyle="primary"
					confirmBtnText="Ok"
					btnSize=""
				>
					Não foi possível realizar integração!
				</ReactBSAlert>
			)

			console.log(err)
		}
	}

	useEffect(() => {
		getSistemas()
	}, [])

	const customStyles = {
		control: (base: any) => ({
			...base,
			height: "calc(2.75rem + 2px)",
		}),
	}

	return (
		<>
			{alert}
			<Modal isOpen={show} toggle={handleClose}>
				<ModalHeader>Nova Integração</ModalHeader>
				<ModalBody style={{ padding: "0 1.5rem" }}>
					<FormGroup>
						<Label for="integration">Integração</Label>
						<Select
							id="integration"
							className="basic-single"
							classNamePrefix="select"
							isSearchable
							styles={customStyles}
							placeholder="Selecione um portal"
							options={sistemas?.map((sistema: any) => ({
								value: sistema.id,
								label: sistema.nome,
							}))}
							onChange={(e: any) => {
								setSelectedIntegration(sistemas?.find((item) => item.id === e.value))
							}}
						/>
						{selectedIntegration?.tipo === "text" && (
							<span>
								<InputGroup className="mt-3">
									<label htmlFor="valor">Valor:</label>
									<Input
										style={{ width: "100%" }}
										id="valor"
										placeholder={
											selectedIntegration?.hash === "salesforce-company-url"
												? "https://login.salesforce.com/"
												: ""
										}
										value={valor}
										onChange={(e) => setValor(e.target.value)}
									/>
								</InputGroup>
								{selectedIntegration?.hash === "salesforce-company-url" && (
									<p>Insira a URL base do seu salesforce.</p>
								)}
							</span>
						)}

						{selectedIntegration && (
							<span>
								<InputGroup className="mt-3">
									<label htmlFor="equipes">Equipes:</label>
									<Select2
										id="equipes"
										maximumInputLength={100}
										className="form-control"
										onSelect={(e) => setEquipe(e.target.value)}
										data={equipes.map((item) => ({ text: item.nome, id: item.id }))}
										value={equipe}
									/>
								</InputGroup>
							</span>
						)}
					</FormGroup>
				</ModalBody>

				<ModalFooter style={{ paddingTop: ".25rem" }}>
					<Button color="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					{selectedIntegration?.tipo === "text" ? (
						valor ? (
							<Button
								color="primary"
								onClick={() => {
									handleIntegrateNoOauth(selectedIntegration.hash)
								}}
							>
								Integrar
								<Spinner
									style={{ marginLeft: "4px" }}
									hidden={!isLoading}
									className="mr-2"
									color="light"
									size="sm"
								/>
							</Button>
						) : selectedIntegration?.hash === "mercado-livre" ? (
							<Button
								color="primary"
								onClick={async () => {
									await handleAdCreate(selectedIntegration.hash)

									window.location.href = `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=${selectedIntegration.app_id}&redirect_uri=https://app.playnee.com/admin/callback/mercado-livre`
								}}
							>
								Integrar
								<Spinner
									style={{ marginLeft: "4px" }}
									hidden={!isLoading}
									className="mr-2"
									color="light"
									size="sm"
								/>
							</Button>
						) : (
							<Button disabled color="primary" onClick={() => {}}>
								Integrar
							</Button>
						)
					) : selectedIntegration ? (
						<Button
							color="primary"
							// onClick={() => {
							// 	window.location.href = selectedIntegration.callbackURL
							// }}
						>
							Integrar
						</Button>
					) : (
						<Button disabled color="primary" onClick={() => {}}>
							Integrar
						</Button>
					)}
				</ModalFooter>
			</Modal>
		</>
	)
}
