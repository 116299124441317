import React, { useState, useRef, useContext } from 'react';
import moment from 'moment'
import AuthContext from "../../../contexts/Auth";
import NotificationAlert from "react-notification-alert";
import { usePersistedState } from '../../../hooks'
import Chart from "chart.js";
import {
    // global options for the charts
    chartOptions,
    // function that adds the global options to our charts
    parseOptions,
} from "../../../variables/charts";

import Filters from './Filters'
import Periodos from './Periodos'
import Informativos from './Informativos'
import Graficos from './Graficos'
// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'
import { useEffect } from 'react';
import { Ranking } from './charts/RankingColaboradores/PlayneeRanking';

export default ({ ...props }) => {

    const { user, hasPermission } = useContext(AuthContext)
    const [mes, setMes] = usePersistedState('mes', moment().format('MM'))
    const [ano, setAno] = usePersistedState('ano', moment().format('YYYY'))
    const [colaborador, setColaborador] = usePersistedState('colaboradorSelecionado', null)
    const [equipe, setEquipe] = usePersistedState('equipeSelecionada', null)
    const [campanha, setCampanha] = useState(null)
    const [unidade, setUnidade] = usePersistedState('unidade', 'Q');
    // const [unidade, setUnidade] = useState();
    const [tipoProduto, setTipoProduto] = usePersistedState('tipoProdutoSelecionado', {});

    const [periodo, setPeriodo] = usePersistedState('periodo', null);

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const notificationAlert = useRef();

    const isGestor = hasPermission('ver-dashboard-gestao')

    useEffect(() => {
        setCampanha(null)
    }, [mes, ano])

    useEffect(() => {
        if (!isGestor) {
            setColaborador(user.id)
        }
    }, [isGestor])

    
    useEffect(() => {
        if (!colaborador || colaborador <= 0) {
            setPeriodo(null)
        }
    }, [colaborador])

    useEffect(() => {
        if (window.Chart) parseOptions(Chart, chartOptions());
    }, [])

    function notify(type, msg) {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (notificationAlert && notificationAlert.current) notificationAlert.current.notificationAlert(options);

    }

    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <SimpleHeader name="Metas" parentName="Dashboard" {...props} />
            <Filters
                mes={mes}
                ano={ano}
                tipoProduto={tipoProduto}
                unidade={unidade}
                equipe={equipe}
                colaborador={colaborador}
                setMes={setMes}
                setAno={setAno}
                setTipoProduto={setTipoProduto}
                setUnidade={(value) => {/*console.log(value);*/ setUnidade(value);}}
                campanha={campanha}
                setColaborador={setColaborador}
                setEquipe={setEquipe}
                isGestor={isGestor}
                notify={notify}
            />

            {(!isGestor || (colaborador && colaborador > 0)) &&
                <Periodos
                    notify={notify}
                    isGestor={isGestor}

                    mes={mes}
                    ano={ano}
                    equipe={equipe}
                    colaborador={colaborador}
                    unidade={unidade}
                    tipoProduto={tipoProduto}

                    periodo={periodo}
                    setPeriodo={setPeriodo}
                />
            }

            <Informativos
                mes={mes}
                ano={ano}
                tipoProduto={tipoProduto}
                unidade={unidade}
                periodo={periodo}
                colaborador={colaborador}
                isGestor={isGestor}
                notify={notify}
            />

            <Graficos
                notify={notify}
                mes={mes}
                ano={ano}
                equipe={equipe}
                colaborador={colaborador}
                isGestor={isGestor}
                unidade={unidade}
                periodo={periodo}
                tipoProduto={tipoProduto}
            />

        </>
    );
}
