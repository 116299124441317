import React, { useState } from "react";
import Select from "react-select";

interface WhatsAppInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ddiOptions = [
  { value: "+1", label: "🇺🇸 +1 (Estados Unidos/Canadá)" },
  { value: "+7", label: "🇷🇺 +7 (Rússia)" },
  { value: "+20", label: "🇪🇬 +20 (Egito)" },
  { value: "+27", label: "🇿🇦 +27 (África do Sul)" },
  { value: "+30", label: "🇬🇷 +30 (Grécia)" },
  { value: "+31", label: "🇳🇱 +31 (Holanda)" },
  { value: "+32", label: "🇧🇪 +32 (Bélgica)" },
  { value: "+33", label: "🇫🇷 +33 (França)" },
  { value: "+34", label: "🇪🇸 +34 (Espanha)" },
  { value: "+39", label: "🇮🇹 +39 (Itália)" },
  { value: "+44", label: "🇬🇧 +44 (Reino Unido)" },
  { value: "+49", label: "🇩🇪 +49 (Alemanha)" },
  { value: "+55", label: "🇧🇷 +55 (Brasil)" },
  { value: "+61", label: "🇦🇺 +61 (Austrália)" },
  { value: "+64", label: "🇳🇿 +64 (Nova Zelândia)" },
  { value: "+65", label: "🇸🇬 +65 (Singapura)" },
  { value: "+81", label: "🇯🇵 +81 (Japão)" },
  { value: "+82", label: "🇰🇷 +82 (Coreia do Sul)" },
  { value: "+86", label: "🇨🇳 +86 (China)" },
  { value: "+91", label: "🇮🇳 +91 (Índia)" },
  { value: "+92", label: "🇵🇰 +92 (Paquistão)" },
  { value: "+94", label: "🇱🇰 +94 (Sri Lanka)" },
  { value: "+98", label: "🇮🇷 +98 (Irã)" },
  { value: "+212", label: "🇲🇦 +212 (Marrocos)" },
  { value: "+213", label: "🇩🇿 +213 (Argélia)" },
  { value: "+216", label: "🇹🇳 +216 (Tunísia)" },
  { value: "+234", label: "🇳🇬 +234 (Nigéria)" },
  { value: "+255", label: "🇹🇿 +255 (Tanzânia)" },
  { value: "+256", label: "🇺🇬 +256 (Uganda)" },
  { value: "+264", label: "🇳🇦 +264 (Namíbia)" },
  { value: "+265", label: "🇲🇼 +265 (Malawi)" },
  { value: "+267", label: "🇧🇼 +267 (Botswana)" },
  { value: "+350", label: "🇬🇮 +350 (Gibraltar)" },
  { value: "+351", label: "🇵🇹 +351 (Portugal)" },
  { value: "+352", label: "🇱🇺 +352 (Luxemburgo)" },
  { value: "+353", label: "🇮🇪 +353 (Irlanda)" },
  { value: "+354", label: "🇮🇸 +354 (Islândia)" },
  { value: "+355", label: "🇦🇱 +355 (Albânia)" },
  { value: "+356", label: "🇲🇹 +356 (Malta)" },
  { value: "+357", label: "🇨🇾 +357 (Chipre)" },
  { value: "+358", label: "🇫🇮 +358 (Finlândia)" },
  { value: "+370", label: "🇱🇹 +370 (Lituânia)" },
  { value: "+371", label: "🇱🇻 +371 (Letônia)" },
  { value: "+372", label: "🇪🇪 +372 (Estônia)" },
  { value: "+373", label: "🇲🇩 +373 (Moldávia)" },
  { value: "+374", label: "🇦🇲 +374 (Armênia)" },
  { value: "+375", label: "🇧🇾 +375 (Bielorrússia)" },
  { value: "+376", label: "🇦🇩 +376 (Andorra)" },
  { value: "+377", label: "🇲🇨 +377 (Mônaco)" },
  { value: "+378", label: "🇸🇲 +378 (San Marino)" },
  { value: "+380", label: "🇺🇦 +380 (Ucrânia)" },
  { value: "+381", label: "🇷🇸 +381 (Sérvia)" },
  { value: "+385", label: "🇭🇷 +385 (Croácia)" },
  { value: "+386", label: "🇸🇮 +386 (Eslovênia)" },
  { value: "+387", label: "🇧🇦 +387 (Bósnia e Herzegovina)" },
  { value: "+389", label: "🇲🇰 +389 (Macedônia do Norte)" },
];

const PhoneInput: React.FC<WhatsAppInputProps> = ({ value, onChange }) => {
  const [ddi, setDdi] = useState("+55");

  const handleDdiChange = (selectedOption: { value: string } | null) => {
    const selectedDdi = selectedOption?.value || "+55";
    setDdi(selectedDdi);

    const currentNumber = value.split(" ").slice(1).join(" ");
    const inputEvent = {
      target: { value: `${selectedDdi} ${currentNumber}` },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange(inputEvent);
  };
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let currentNumber = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
    if (currentNumber.startsWith("0")) {
      return;
    }

    // Limita o número máximo de caracteres no número (11 dígitos, excluindo DDI)
    if (currentNumber.length > 11) {
      currentNumber = currentNumber.slice(0, 11);
    }

    // Aplica a formatação para separar DDD e resto do número
    if (currentNumber.length > 2) {
      currentNumber = `${currentNumber.slice(0, 2)} ${currentNumber.slice(2)}`;
    }

    const formattedValue = `${ddi.replace("+", "")} ${currentNumber}`;
    const inputEvent = {
      target: { value: formattedValue },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange(inputEvent);
  };
  const getPhoneNumber = () => {
    // Retorna o número sem o DDI
    const parts = value.split(" ");
    if (parts.length > 1) {
      return parts.slice(1).join(" "); // Retorna apenas o número
    }
    return ""; // Retorna vazio se não houver número
  };

  return (
    <div>
      <label htmlFor="ddi">Selecione o DDI</label>
      <Select
        id="ddi"
        options={ddiOptions}
        value={ddiOptions.find((option) => option.value === ddi)}
        onChange={handleDdiChange}
        placeholder="Escolha um país"
      />
      <label htmlFor="phone-number">Número de WhatsApp</label>
      <input
        id="phone-number"
        type="text"
        value={getPhoneNumber()} // Exibe o número sem o DDI
        onChange={handlePhoneNumberChange}
        placeholder="Digite o número"
        className="form-control"
      />
    </div>
  );
};

export default PhoneInput;
