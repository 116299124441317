import React, { useRef, useState, useEffect, useContext } from "react";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
import NotificationAlert from "react-notification-alert";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Collapse,
    Toast,
    ToastHeader,
    ToastBody,
} from "reactstrap";
import './style.css';
import FormOportunidade from "./formulario/Form";
import api from "../../../../../services/api";
import EmpresaContext from "../../../../../contexts/Empresa";
import { AxiosResponse } from "axios";

export default function Automac() {
    const notificationAlert = useRef<any>();
    const [isOpenOportunidades, setIsOpenOportunidades] = useState(false);
    const [isOpenAnuncios, setIsOpenAnuncios] = useState(false);
    const [isOpenClientes, setIsOpenClientes] = useState(false);
    const [displayForm, setDisplayForm] = useState("none");
    const [displayMain, setDisplayMain] = useState("flex");
    // const [displayForm, setDisplayForm] = useState("flex");
    // const [displayMain, setDisplayMain] = useState("none");
    const [automacoesInfo, setAutomacoesInfo] = useState([]);
    const { empresaSelecionada } = useContext(EmpresaContext);
    const [eventoID, setEventoID] = useState(new Number);
    const [nomeEntidade, setNomeEntidade] = useState([]);
    const [eventos, setEventos] = useState([]);
    const [acoesBtn, setAcoesBtn] = useState([]);

    const notify = (type:any, msg:string) => {
        let options = {
          place: "tc",
          message: (
            <div className="alert-text" style={{ zIndex: 100 }}>
              <span data-notify="message">{msg}</span>
            </div>
          ),
          type: type,
          icon: "ni ni-bell-55",
          autoDismiss: 3,
        };
        if (notificationAlert.current)
          notificationAlert.current.notificationAlert(options);
      };
    interface eventoType {
        descricao: String,
        entidadeid: number,
        eventoid: number,
        nomeentidade: String,
        nomeevento: String,
        quant_automacoes_empresa: number,
        total_automacoes: number,
    }

    interface entidadeType {
        id: number,
        nome: String,
    }

    // interface automacoesModel {
    //     nomeEvento: String;
    //     descricao: String;
    //     eventoID: number;
    // }

    // trocar essa função ou estudar uma lógica para fazer modificar essa função e não ficar dados fixos
    const toggle = (e) => {
        switch (e) {
            case "oportunidades":
                setIsOpenOportunidades(!isOpenOportunidades)
                break;
            case "anúncios":
                setIsOpenAnuncios(!isOpenAnuncios);
                break;
            // case "clientes":
            //     setIsOpenClientes(!isOpenClientes);
            //     break;
        }
    };

    async function reqAutomacoes() {
        await api.get(`automacoes/${empresaSelecionada?.id}`).then(response => {
            setEventos(response.data.eventos);
            setNomeEntidade(response.data.nomeEntidade);
        }).catch(err => {
            notify('danger', 'Erro, tente novamente ou contate o ADM.');
        });
    };

    async function reqEventoAutomacao(eventoID: number): Promise<AxiosResponse<any>> {
        return await api.get(`automacoes/${empresaSelecionada?.id}/eventoautomacao/${eventoID}`);
    };

    async function reqAcoes(eventoID: number): Promise<AxiosResponse<any>> {
        return await api.get(`/automacoes/${empresaSelecionada?.id}/acoes/${eventoID}`);
    }

    function loadForm(eventoID: Number) {
        reqEventoAutomacao(Number(eventoID)).then(response => {
            setDisplayForm("flex");
            setDisplayMain("none");
            setAutomacoesInfo(response.data);
            setEventoID(eventoID);
        }).catch(err => {
            setDisplayForm("none");
            setDisplayMain("flex");
            notify('danger', 'Erro ao carregar as automações e seus estados.');
        });

        reqAcoes(Number(eventoID)).then(respose => {
            setAcoesBtn(respose.data);
        }).catch(err => {
            setDisplayForm("none");
            setDisplayMain("flex");
            notify('danger', 'Erro ao carregar as automações não utilizadas.');
        });
    }

    useEffect(() => {
        reqAutomacoes();
    }, []);

    return (
        <>
            {/* {alert} */}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <SimpleHeader name="Automações" parentName="Minha empresa" />
            <div className="container-fluid mt--6">
                <Row>
                    <Col>
                        <FormOportunidade
                            displayForm={displayForm}
                            setDisplayForm={setDisplayForm}
                            setDisplayMain={setDisplayMain}
                            automacoesInfo={automacoesInfo}
                            eventoID={eventoID}
                            loadForm={() => { loadForm(eventoID) }}
                            reqAutomacoes={() => { reqAutomacoes() }}
                            acoesBtnInfo={acoesBtn}
                        />
                        <Card style={{ display: displayMain }}>
                            <CardHeader style={{ position: "sticky", top: 0, zIndex: 1, }}>
                                <h1>Automações</h1>
                            </CardHeader>
                            {nomeEntidade.map((entidade: entidadeType) => {
                                return (
                                    <CardBody outline body>
                                        <div className="checklist-item checklist-item-info">
                                            <Button color="white" onClick={() => { toggle(`${entidade.nome.toLowerCase()}`) }}>
                                                {entidade.nome}
                                            </Button>
                                        </div>
                                        <Collapse className="collapse-menu" isOpen={entidade.nome === "Oportunidades" ? isOpenOportunidades : isOpenAnuncios}>
                                            <div className="p-3 my-2 rounded" style={{ display: "flex" }}>
                                                {eventos.map((values: eventoType) => {
                                                    if (entidade.nome === values.nomeentidade) {
                                                        return (
                                                            <Toast className="evento-toast" onClick={() => {
                                                                loadForm(values.eventoid);
                                                            }}>
                                                                <ToastHeader style={{ backgroundColor: "#F7F7F7" }}>
                                                                    {values.nomeevento}
                                                                </ToastHeader>
                                                                <ToastBody className="evento-toast-body">
                                                                    <div className="text-automacao">
                                                                        <p>{values.descricao}</p>
                                                                    </div>
                                                                    <p className="num-automacao">{values.quant_automacoes_empresa} de {values.total_automacoes} automações</p>
                                                                </ToastBody>
                                                            </Toast>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </Collapse>
                                    </CardBody>
                                );
                            })}
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}