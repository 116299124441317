import React, { useContext, useEffect, useState } from "react"
import { ButtonContainer } from "../../styles"
import { Button, ListGroup, ListGroupItem, Spinner, UncontrolledTooltip } from "reactstrap"
import { GoogleIcon } from "../../../../../../security/Users/me/components/Canais/components/GoogleIcon"
import ReactBSAlert from "react-bootstrap-sweetalert"
import { useGoogleLogin } from "@react-oauth/google"
import api from "../../../../../../../services/api"
import Item from "../../../Pagamentos/List/Item"
import EmpresaContext from "../../../../../../../contexts/Empresa"

interface Email {
	id: number
	empresa_id?: number
	user_id?: number
	sistema_id: number
	valor: string
	created_at: Date
	updated_at: Date
	is_revoked: boolean
	nome_sistema: string
	hash_sistema: string
	tipo_sistema: string
}

export const EmailsIntegrados: React.FC = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [isEmailSync, setIsEmailSync] = useState(false)
	const [alert, setAlert] = useState<any>(null)
	const [emails, setEmails] = useState<Email[]>([])

	const { empresaSelecionada } = useContext(EmpresaContext)

	const getEmails = async () => {
		const response = await api.get(`esb/integracoes/ativas?types[]=service&empresaId=${empresaSelecionada?.id}`)

		setEmails(response.data)
	}

	useEffect(() => {
		getEmails()
	}, [])

	const googleLogin = useGoogleLogin({
		flow: "auth-code",
		scope: "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/userinfo.email",
		onSuccess: async (codeResponse) => {
			try {
				setIsLoading(true)
				const googleResponse = await api.post("esb/integracoes/auth/gmail", {
					code: codeResponse.code,
				})

				setAlert(
					<ReactBSAlert
						success
						style={{ display: "block" }}
						title="Sucesso!"
						onConfirm={() => setAlert(null)}
						onCancel={() => setAlert(null)}
						confirmBtnBsStyle="primary"
						confirmBtnText="Ok"
						btnSize=""
					>
						{googleResponse.data.message}
					</ReactBSAlert>
				)

				getEmails()

				setIsEmailSync(true)
				setIsLoading(false)
			} catch (err) {
				setIsLoading(false)

				setAlert(
					<ReactBSAlert
						error
						style={{ display: "block" }}
						title="Falha!"
						onConfirm={() => setAlert(null)}
						onCancel={() => setAlert(null)}
						confirmBtnBsStyle="primary"
						confirmBtnText="Ok"
						btnSize=""
					>
						Não foi possível adicionar E-mail!
					</ReactBSAlert>
				)

				console.log(err)
			}
		},
		onError: (errorResponse) => {
			setAlert(
				<ReactBSAlert
					success
					style={{ display: "block" }}
					title="Falha!"
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnBsStyle="warn"
					confirmBtnText="Ok"
					btnSize=""
				>
					Não foi possível adicionar E-mail!
				</ReactBSAlert>
			)

			console.log(errorResponse)
		},
	})

	const deleteIntegration = async (id: number) => {
		try {
			setIsLoading(true)

			await api.delete(`esb/integracoes/${id}`)

			getEmails()
			setIsLoading(false)

			setAlert(
				<ReactBSAlert
					success
					style={{ display: "block" }}
					title="Sucesso!"
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnBsStyle="primary"
					confirmBtnText="Ok"
					btnSize=""
				>
					Integração removida com sucesso!
				</ReactBSAlert>
			)
		} catch (err) {
			setIsLoading(false)

			setAlert(
				<ReactBSAlert
					error
					style={{ display: "block" }}
					title="Falha!"
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnBsStyle="primary"
					confirmBtnText="Ok"
					btnSize=""
				>
					Não foi possível remover integração!
				</ReactBSAlert>
			)

			console.log(err)
		}
	}

	function handleDeleteIntegration(id: number) {
		setAlert(
			<ReactBSAlert
				warning
				style={{ display: "block" }}
				title="Tem certeza que deseja remover email?"
				onConfirm={() => deleteIntegration(id)}
				onCancel={() => setAlert(null)}
				confirmBtnBsStyle="danger"
				confirmBtnText="Sim"
				btnSize=""
				showCancel
				focusCancelBtn
			/>
		)
	}

	return (
		<>
			{alert}
			<div>
				<ButtonContainer className="mb-4">
					<Button caret color="secondary" onClick={googleLogin}>
						<GoogleIcon />
						<span className="btn-inner--text">Conectar Gmail</span>
					</Button>
				</ButtonContainer>
				<ListGroup className="list " flush>
					{emails.length <= 0 && (
						<ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
							<h3 className="text-center">Ainda não há nenhum email integrado.</h3>
						</ListGroupItem>
					)}
					{emails?.map((item: Email) => {
						return (
							<ListGroupItem
								key={item.id}
								className="checklist-entry flex-column align-items-start py-4 px-4"
							>
								<div className="d-flex justify-content-between">
									<div
										style={{ alignItems: "center", gap: ".5rem" }}
										className="d-flex checklist-primary"
									>
										<GoogleIcon />
										<h5 className="checklist-title mb-0">{item.valor} </h5>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
										}}
									>
										<div className="custom-control custom-checkbox custom-checkbox-primary">
											<div className="btn-group" role="group">
												<Button
													id={"Tooltip-2" + item.id}
													className="btn-sm"
													color="danger"
													onClick={() => {
														handleDeleteIntegration(item.id)
													}}
												>
													<i className="fas fa-trash pr-2"></i>
													Remover
													<Spinner
														style={{ marginLeft: "4px" }}
														hidden={!isLoading}
														className="mr-2"
														color="light"
														size="sm"
													/>
												</Button>

												<UncontrolledTooltip
													placement="left-end"
													target={"Tooltip-2" + item.id}
												>
													Clique para remover o email: {item.valor}
												</UncontrolledTooltip>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
						)
					})}
				</ListGroup>
			</div>
		</>
	)
}
