import React, { useContext, useEffect, useState } from "react"
import { TabContent, TabPane, Nav, Button } from "reactstrap"
import AccountsListGroup from "./AccountsListGroup"
import ContaSocialCard from "./components/ContaSocialCard"
import FacebookAds from "../FacebookAds"
import Emails from "../Emails"
import { EmailsIntegrados } from "../Emails/EmailsIntegrados"
import api from "../../../../../../services/api"
import "./components/styles.css"
import EmpresaContext from "../../../../../../contexts/Empresa"
import { Portais } from "../Emails/Portais"

export default function ContasSociais() {
	const [activeTab, setActiveTab] = useState("hidden")
	const [fbStatus, setFbStatus] = useState<any>(false)
	const [accounts, setAccounts] = useState<any>([])
	const [malaDiretaEmails, setMalaDiretaEmails] = useState<any>([])
	const [connectedPages, setConnectedPages] = useState([])
	const [emailsIntegrados, setEmailsIntegrados] = useState([])
	const [portaisIntegrados, setPortaisIntegrados] = useState([])
	const { empresaSelecionada } = useContext(EmpresaContext)

	async function carregarEquipesEmAccounts() {
		const { data } = await api.get("/meta/all-accounts")
		setAccounts(data)
	}
	const toggle = () => {
		setActiveTab("hidden")
	}

	useEffect(() => {
		carregarEquipesEmAccounts()
		checkFacebookStatus()
		checkMalaDiretaStatus()
		checkEmailsIntegradosStatus()
		getIntegrations()
	}, [])

	async function checkFacebookStatus() {
		const response = await api.get("common/canais/facebook")
		const { is_valid, pages } = response.data
		setFbStatus(is_valid)
		setConnectedPages(pages)
	}

	async function checkMalaDiretaStatus() {
		const response = await api.get(`common/empresas/${empresaSelecionada?.id}/emails`)

		setMalaDiretaEmails(response.data)
	}

	const checkEmailsIntegradosStatus = async () => {
		const response = await api.get("esb/integracoes/ativas?types=service")

		console.log(response)
		setEmailsIntegrados(response.data)
	}

	const getIntegrations = async () => {
		const response = await api.get(
			`esb/integracoes/ativas?&types[]=text&types[]=oauth&empresaId=${empresaSelecionada?.id}`
		)

		setPortaisIntegrados(response.data)
	}

	return (
		<>
			{activeTab === "hidden" && (
				<Nav role="tablist" style={{ gap: "1rem" }}>
					<ContaSocialCard
						isConnected={accounts.length > 0}
						accounts={accounts}
						icon="fab fa-whatsapp"
						name="whataspp-ba"
						setTabActive={() => setActiveTab("whatsapp-ba")}
						title="WhatsApp"
						type="phone"
					/>
					<ContaSocialCard
						isConnected={fbStatus}
						icon="fab fa-facebook"
						name="facebookads"
						setTabActive={() => setActiveTab("facebookads")}
						title="Facebook Ads"
					/>
					<ContaSocialCard
						isConnected={malaDiretaEmails.length > 0}
						accounts={malaDiretaEmails}
						icon="fas fa-envelope"
						name="emails-maladireta"
						setTabActive={() => setActiveTab("emails-maladireta")}
						title="Mala Direta Via Emails"
						type="email"
					/>
					<ContaSocialCard
						isConnected={emailsIntegrados.length > 0}
						accounts={emailsIntegrados}
						icon="fas fa-envelope"
						name="emails-integrados"
						setTabActive={() => setActiveTab("emails-integrados")}
						title="Emails - Integrações"
						type="email"
					/>
					<ContaSocialCard
						isConnected={portaisIntegrados.length > 0}
						accounts={portaisIntegrados}
						icon="fas fa-mail-bulk"
						name="portais-integrados"
						setTabActive={() => setActiveTab("portais-integrados")}
						title="Portais"
						type="portais"
					/>
				</Nav>
			)}
			<TabContent activeTab={activeTab}>
				<TabPane tabId="whatsapp-ba">
					<AccountsListGroup hideTab={toggle} accounts={accounts} onSuccess={carregarEquipesEmAccounts} />
				</TabPane>

				<TabPane tabId="facebookads">
					<Button onClick={toggle}>Voltar</Button>
					<FacebookAds fbStatus={fbStatus} connectedPages={connectedPages} />
				</TabPane>

				<TabPane tabId="emails-maladireta">
					<Button onClick={toggle}>Voltar</Button>
					<Emails />
				</TabPane>

				<TabPane tabId="emails-integrados">
					<Button onClick={toggle}>Voltar</Button>
					<EmailsIntegrados />
				</TabPane>

				<TabPane tabId="portais-integrados">
					<Button onClick={toggle}>Voltar</Button>
					<Portais />
				</TabPane>
			</TabContent>
		</>
	)
}
